<template>
  <div id="app">
    <waterMark overlapStyle="height: 100%;" :text="waterMarktext" v-if="isShow"> </waterMark>
    <router-view />
  </div>
</template>
<script>

import waterMark from '@/components/waterMark'
export default {
  components: { waterMark},
  data() {
    return {
      waterMarktext:[],
      isShow:false
    }
  },
  watch: {
    '$route'(to, from) {
      this.routeFn(to)
      // 路由变化时会触发这个函数
      // 'to' 和 'from' 是两个路由对象
      console.log('路由变化了:', from.path, '->', to.path);
      // 你可以在这里根据路由变化执行相应的逻辑
    }
  },
  methods:{
    routeFn(to){
      const userInfoStr = localStorage.getItem('userInfo');
      if (userInfoStr) {
        const userInfo = JSON.parse(userInfoStr);
        if(to.path === '/user/bind' || to.path === '/user/load_wx_config' || to.path === '/user/null'||to.path === '/home/clear_cache'){
          this.isShow = false;
        }else{
          this.waterMarktext = [`姓名：${userInfo.name}`, `手机号：${userInfo.phone}`];
          this.isShow = true;
        }
      }
    } 
  }
}
</script>

<style lang="less"></style>
