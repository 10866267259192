
export function checkBind(router) {

	var res = JSON.parse(localStorage.getItem('userInfo'));
	console.log(res, 'checkBind')

	if (!res || !res.name) {
		router.push({
			path: `/user/bind`
		})
		return false;
	}

	return true;
}

export function checkLoadWxConfig(router) {

	var res = localStorage.getItem('loadWxConfig');
	console.log(res, 'checkLoadWxConfig')

	if (!res) {
		router.push({
			path: `/user/load_wx_config`
		})
		return false;
	}
	localStorage.removeItem('loadWxConfig')

	return true;
}

export function getUserInfo() {
	let res = {
		'userInfo': JSON.parse(localStorage.getItem('userInfo')),
		'qy_out_user_id': localStorage.getItem("qy_out_user_id"),
		// 'outUserInfo': JSON.parse(localStorage.getItem("outUserInfo"))
	};

	console.log('getUserInfo', res);

	return res;
}

export function setEnterPath(path) {
	localStorage.setItem("enterPath", path);
}

export function gotoEnterPath(router) {
	let enterPath = localStorage.getItem("enterPath");
	router.push({
		path: `${enterPath}`
	})
}

export function clearCacheData() {
	localStorage.removeItem("token")
	localStorage.removeItem("userInfo");
	localStorage.removeItem("qy_out_user_id");
	localStorage.removeItem("loadWxConfig");
	localStorage.removeItem("getQyConfigStrCache");
	localStorage.removeItem("jsapiTicketCache");
	localStorage.removeItem("ticketCache");
	localStorage.removeItem("userId");
	localStorage.removeItem("configType");
}