import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./normalize.less";
import cookies from 'js-cookie'
import vant from 'vant'
import 'vant/lib/index.css';
import 'lib-flexible/flexible'
//rem.js文件代码 ，再mian.js引进
// 基准大小
const baseSize = 32
// 设置 rem 函数
function setRem () {
  // 当前页面宽度相对于 750 宽的缩放比例，可根据自己需要修改。
  const scale = document.documentElement.clientWidth / 750
  // console.log(scale)
  // 设置页面根节点字体大小
  document.documentElement.style.fontSize = (baseSize * Math.min(scale, 2)) + 'px'
  // console.log(document.documentElement.style.fontSize)
}
// 初始化
setRem()
// 改变窗口大小时重新设置 rem
window.onresize = function () {
  setRem()
}


Vue.use(vant)
Vue.use(cookies)
Vue.config.productionTip = false;


new Vue({
  router,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
