<template>
  <div :style="overlapStyle" >
    <slot></slot>
    <div 
        class="bit-mark-wrap" 
        :style="{'background-image': `url(${base64URL})` ,'background-size': `${gapX}px ${gapY}px`}  " 
    ></div>
    <canvas class="bit-watermark-canvas" ref="canvas" width="450" height="320" />
  </div>
</template>

<script>
  /**
   * 背景水印组件
   */
  export default {
    name: 'Watermark',
    props: {
      text: Array,
      overlapStyle: {
        type: String,
        default () {
          return ''
        }
      },
      width: {
        type: Number,
        default () {
          return 300
        }
      },
      height: {
        type: Number,
        default () {
          return 200
        }
      },
      fontSize: {
        type: Number,
        default () {
          return 24
        }
      },
      fontColor: {
        type: String,
        default () {
          return '#ddd'
        }
      },
      rotate: {
        type: Number,
        default () {
          return 15
        }
      },
      gapX: {
        type: Number,
        default () {
          return 200
        }
      },
      gapY: {
        type: Number,
        default () {
          return 134
        }
      }
    },
    data () {
      return {
        base64URL: ''
      }
    },
    mounted () {
      this.drawInit()
     
    },
    methods: {
      drawInit: function () {
        // const { text, width, height, fontSize, fontColor, rotate } = this
        // let ctx = this.$refs.canvas.getContext('2d')
        // ctx.width = width
        // ctx.height = height
        // ctx.font = ` 700 ${fontSize}px normal`
        // ctx.fillStyle = fontColor
        // ctx.textAlign ='center'
        // ctx.textBaseline ='middle'
        // ctx.translate(0, 0)
        // ctx.rotate(rotate * Math.PI / 180)
        // ctx.fillText(text[0],20, height)
        // ctx.fillText(text[1],10, height*3)

        
        // // 转base64图片
        // this.base64URL = this.$refs.canvas.toDataURL('image/png')


        const { text, width, height, fontSize, fontColor, rotate } = this
        let ctx = this.$refs.canvas.getContext('2d')
        ctx.save();
        ctx.font = `${fontSize}px normal`;
        ctx.width = width
        ctx.height = height
        ctx.fillStyle = fontColor;
        ctx.translate(0, 0);
        ctx.rotate(Math.atan(ctx.width / (2 * ctx.height)));
        ctx.fillText(text[0], 20, 0);
        ctx.fillText(text[1], 30, height/5);
        ctx.restore();
        this.base64URL = this.$refs.canvas.toDataURL('image/png')
      }
    }
  }
</script>

<style >
 .bit-watermark-canvas {
    position: fixed;
    bottom: -1000px;
    right: -1000px;
  }
  
  .bit-mark-wrap {
    z-index: 99;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-size: 320px;
    pointer-events: none;
    background-repeat: repeat;
  }
</style>
